<template>
  <div>
    <UITabs class-button="block mb-4" class="flex w-full" :tabs="tabs" v-model="tab" />

    <h1 class="text-2xl font-semibold text-gray-900">
      Gastenboek ({{ apiData.is_active ? 'Online' : 'Niet online' }})
    </h1>

    <button v-if="apiData.is_active" @click="handleToevoegen">+ Gastenboek entry toevoegen</button>

    <div class="py-4" :key="Object.values(apiData).join('-')">
      <UITableResponsive :items="items" :headers="['id', 'datum', 'gemeente', 'bericht']" :suffix-headers="['Acties']">
        <template v-slot:colgroup>
          <colgroup>
            <col class="w-0" />
            <col class="w-0" />
            <col class="w-0" />
            <col class="w-auto" />
            <col class="w-0" />
          </colgroup>
        </template>
        <template v-slot:item-datum="{ item }">
          {{ dateTimeStringLocale(item.creation_date)}}
        </template>
        <template v-slot:item-bericht="{ item }">
          <p class="block pr-8 break-words whitespace-normal">
            {{ item.bericht }}
          </p>
        </template>
        <template v-slot:item-Acties="{ item }">
          <button
            class="btn"
            @click="setActivation(item.id)"
            :class="{
              error: apiData.is_active,
              success: !apiData.is_active,
            }"
          >
            {{ apiData.is_active ? 'Offline plaatsen' : 'Online plaatsen' }}
          </button>
          <button v-if="apiData.is_active" @click="handleEdit(item)" class="ml-1 btn warn">Wijzigen</button>
        </template>
      </UITableResponsive>
      <UITablePagination class="mt-2" :count="count" v-model:limit="limit" v-model:offset="offset" />
    </div>
  </div>
</template>

<script setup>
import clone from 'just-clone'
import { defineAsyncComponent, watch, computed, inject, ref } from 'vue'
import UITabs from '@/components/UI/Tabs.vue'
import UITableResponsive from '@/components/UI/Table/Responsive'
const UITablePagination = defineAsyncComponent(() => import('@/components/UI/Table/Pagination.vue'))
// const ButtonAlertOpmerking = defineAsyncComponent(() => import('@/components/UI/Button/AlertOpmerking.vue'))

import { dateTimeStringLocale } from '@/functions/formatDate'
import usePaginatedApi from '@/hooks/usePaginatedApi'
import useModal from '@/hooks/useModal'

const tabs = ['Niet online', 'Online']
const tab = ref(0)

const axios = inject('axios')
const [openFixedModal] = useModal({ fixed: true })

const apiData = computed(() => ({ is_active: tab.value === 1 }))
const { limit, offset, count, items, getData } = usePaginatedApi('/api/dashboard/gastenboek/list', apiData)

const setActivation = async id => {
  await axios
    .patch(`/api/dashboard/gastenboek/${apiData.value.is_active ? 'deactivate' : 'activate'}`, { id })
    .then(() => getData())
}

const handleToevoegen = () => {
  openFixedModal('FORM_GASTENBOEK', { callback: getData })
}
const handleEdit = (item) => {
  openFixedModal('FORM_GASTENBOEK', { modelValue: clone(item), callback: getData })
}

watch(
  () => tab.value,
  (v, o) => {
    if (v == o) return
    offset.value = 0
    getData()
  },
)
</script>
